import { ref } from 'vue'

export const postTypeList = ref([
  { id: 'product', name: '商品' },
  { id: 'distrubution', name: '分销' },
  { id: 'store', name: '门店' },
  { id: 'merchant', name: '商家' },
  { id: 'lottery', name: '霸王餐' },
  { id: 'publicProduct', name: '串货' }
])
