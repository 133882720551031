<template lang="pug">
a-modal.modalContainer(
  title="选择组件" 
  ok-text="确认" 
  cancel-text="取消" 
  v-model:visible="isShowComponentPicker" 
  @ok="submitPickComponent"
)
  a-form
    a-form-item(label="类型")
      a-radio-group(button-style="solid" v-model:value="pickType")
        a-radio-button(value="buildIn") 预制组件
        a-radio-button(value="custom") 自定义组件
    template(v-if="pickType==='buildIn'")
      .buildInComponentList
        .item(
          v-for="(i,n) in buildInComponentList" :key="n"
          :class="{active:currentBuildInComponentId===i.id}"
          @click="handleChangeBuildInComponent(i)"
        ) 
          .type
            span(v-if="i.type==='text'") 文本
            span(v-else-if="i.type==='image'") 图片
            span(v-else-if="i.type==='qrCode'") 二维码
          .id {{i.id}}
          .name {{i.name}}
    template(v-if="pickType==='custom'")
      a-form-item(label="组件类型")
        a-radio-group(button-style="solid" v-model:value="form.type")
          a-radio-button(value="image") 图片
          a-radio-button(value="text") 文字
          a-radio-button(value="textarea") 多行文本
      a-form-item(label="Key")
        a-input(v-model:value="form.placeholderKey")
      a-form-item(label="默认值")
        a-textarea(v-model:value="form.defaultValue")
      a-form-item(label="预览")
        template(v-if="form.type==='image'")
          img.imagePreview(:src="form.defaultValue")
        template(v-else)
          .textPreview {{form.defaultValue}}

</template>

<script setup>
import { inject, reactive, ref, onMounted } from 'vue'

import {
  form,
  isShowComponentPicker,
  submitPickComponent,
  buildInComponentList,
  currentBuildInComponentId,
  handleChangeBuildInComponent
} from '../provider/component'

const pickType = ref('buildIn')
</script>

<style lang="less" scoped>
.modalContainer {
  @color: #ff2e97;
  .imagePreview {
    max-width: 200px;
    max-height: 200px;
  }
  .textPreview {
    border: 1px dashed #ccc;
  }
  .buildInComponentList {
    display: flex;
    flex-wrap: wrap;
    .item {
      border: 1px solid @color;
      padding: 5px;
      border-radius: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 130px;
      text-align: center;
      cursor: pointer;
      &.active {
        background: @color;
        color: white;
      }
    }
  }
}
</style>
