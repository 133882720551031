<template lang="pug">
.page.workspacePage
  .workspaceContainer(:class='"workspace-" + workspaceOption.background')
    .workspaceWrap(:style='workspaceWrapStyle')
      #workspace(:style='workspaceStyle')
    .statusBar
      .btn(type='primary', size='small', @click='handleReDrawWorkspace') 重绘
      .btn(type='primary', size='small', @click='handleClearWorkspace') 清空
      .btn(type='primary', size='small', @click='handlePreview') 预览
      .btn(type='primary', size='small', @click='handleGenerate') 生成
      .btn(type='primary', size='small', @click='isShowSaveTemplateDialog = true') 保存为模板
      .btn(type='primary', size='small', @click='$router.push("/asset")') 模板库
  .controlPanel
    a-form.section
      .sectionTitle 画布属性
      a-form(layout='inline')
        a-form-item(label='宽')
          a-input-number(
            size='small',
            :min='100',
            :max='100000',
            :step='100',
            v-model:value='workspaceOption.width'
            @change="handleResizeWorkspace"
          )
        a-form-item(label='高')
          a-input-number(
            size='small',
            :min='100',
            :max='100000',
            :step='100',
            v-model:value='workspaceOption.height',
            @change="handleResizeWorkspace"
          )
      a-form-item(label='画布背景色')
        a-radio-group(
          v-model:value='workspaceOption.canvasBackground',
          button-style='solid',
          size='small'
        )
          a-radio-button(v-for='(i, n) in canvasBackgroundColorList', :key='n', :value='i.value') {{ i.label }}
    .section(v-if='currentSpriteId')
      .sectionTitle 图层属性
      a-form(layout='inline')
        a-form-item(label='X')
          a-input-number(
            size='small',
            :min='0',
            :step='1',
            :max='workspaceOption.width - 10',
            v-model:value='spriteOption.x',
            @change='updateCurrentSpriteOption'
          )
        a-form-item(label='Y')
          a-input-number(
            size='small',
            :min='0',
            :step='1',
            :max='workspaceOption.height - 10',
            v-model:value='spriteOption.y',
            @change='updateCurrentSpriteOption'
          )
      a-form(
        layout='inline',
      )
        a-form-item(label='宽')
          a-input-number(
            size='small',
            :min='10',
            :max='10000',
            :step='10',
            v-model:value='spriteOption.width',
            @change='updateCurrentSpriteOption'
          )
        a-form-item(label='高')
          a-input-number(
            size='small',
            :min='10',
            :max='10000',
            :step='10',
            v-model:value='spriteOption.height',
            @change='updateCurrentSpriteOption'
          )
      a-form
        a-form-item(label='层级')
          a-input-number(
            size='small',
            :min='0',
            :max='200',
            :step='1',
            v-model:value='spriteOption.zIndex',
            @change='updateCurrentSpriteOption'
          )
        a-form-item(label='排版' v-if="spriteOption.type!=='text'")
          a-radio-group(
            button-style='solid',
            size='small'
          )
            a-radio-button(@click="handleComposeSprite('left')") 居左
            a-radio-button(@click="handleComposeSprite('center')") 居中
            a-radio-button(@click="handleComposeSprite('right')") 居右
      template(v-if='spriteOption.type === "image"')
        .sectionTitle 图片属性
        a-form(layout='horizontal')
          a-form-item(label='缩放')
            a-slider(
              size='small',
              :min='0.2',
              :max='3',
              :step='0.1',
              v-model:value='spriteOption.scale',
              @afterChange='afterSpriteScaleChange'
            )
          a-form-item(label='形状')
            a-radio-group(button-style='solid', size='small')
              a-radio-button(@click='handleChangeSpriteToSquare') 方形
              a-radio-button(@click='handleChangeSpriteToRounded') 圆形
      template(v-if='spriteOption.type === "text" || spriteOption.type === "textarea"')
        .sectionTitle 文本属性
        a-form
          a-form-item(label='字号')
            a-input-number(
              size='small',
              :min='12',
              :step='1',
              :max='200',
              v-model:value='spriteOption.textStyle.fontSize',
              @change='updateCurrentSpriteOption'
            )
          a-form-item(label='字体')
            a-select(
              size='small',
              v-model:value='spriteOption.textStyle.fontFamily',
              @change='updateCurrentSpriteOption'
            )
              a-select-option(v-for='(i, n) in textFontFamilyList', :key='n', :value='i.value') {{ i.label }}
          a-form-item(label='颜色')
            a-form(layout='inline')
              a-form-item
                a-select(
                  size='small',
                  v-model:value='spriteOption.textStyle.fillColor',
                  @change='updateCurrentSpriteOption'
                )
                  a-select-option(v-for='(i, n) in textColorList', :key='n', :value='i.value') {{ i.label }}
              a-form-item
                a-input(
                  size='small',
                  v-model:value='spriteOption.textStyle.fillColor',
                  @change='updateCurrentSpriteOption'
                )
          a-form-item(label='文字排版')
            a-radio-group(
            button-style='solid',
            size='small'
            )
              a-radio-button(@click="handleComposeText('left')") 居左
              a-radio-button(@click="handleComposeText('center')") 居中
              a-radio-button(@click="handleComposeText('right')") 居右
          template(v-if='spriteOption.type === "text"')
            a-form-item(label='内容')
              a-input(
                size='small',
                v-model:value='spriteOption.text',
                @change='updateCurrentSpriteOption'
              )
          template(v-if='spriteOption.type === "textarea"')
            a-form-item(label='内容')
              a-textarea(
                size='small',
                v-model:value='spriteOption.text',
                @change='updateCurrentSpriteOption'
              )
          a-form-item(label='加粗')
            a-radio-group(
              v-model:value='spriteOption.textStyle.fontWeight',
              button-style='solid',
              size='small',
              @change='updateCurrentSpriteOption'
            )
              a-radio-button(value='lighter') 更细
              a-radio-button(value='normal') 正常
              a-radio-button(value='bolder') 更粗
          a-form-item(label='描边宽度')
            a-input-number(
              size='small',
              v-model:value='spriteOption.textStyle.strokeWidth',
              :min='0',
              :step='1',
              :max='100',
              @change='updateCurrentSpriteOption'
            )
          a-form-item(label='描边颜色')
          a-form(layout='inline')
            a-form-item
              a-select(
                size='small',
                v-model:value='spriteOption.textStyle.strokeColor',
                @change='updateCurrentSpriteOption'
              )
                a-select-option(v-for='(i, n) in textColorList', :key='n', :value='i.value') {{ i.label }}
            a-form-item
              a-input(
                size='small',
                v-model:value='spriteOption.textStyle.strokeColor',
                @change='updateCurrentSpriteOption'
              )
    a-form.section.spriteList
      .sectionTitle 元素列表
      comSpriteList

comComponentPicker
comSaveTemplateDialog
comPreviewDialog
</template>

<script setup>
import { nanoid } from 'nanoid'
import {
  provide,
  inject,
  onMounted,
  onBeforeMount,
  onUnmounted,
  watch,
  computed,
  ref,
  nextTick
} from 'vue'
const { route, loading } = inject('global')
import comComponentPicker from './module/componentPicker.vue'
import comSaveTemplateDialog from './module/saveTemplateDialog.vue'
import comSpriteList from './module/spriteList.vue'
import comPreviewDialog from './module/previewDialog.vue'

import {
  workspaceOption,
  spriteOption,
  currentSpriteId,
  initWorkspace,
  handleReDrawWorkspace,
  handleClearWorkspace,
  afterSpriteScaleChange,
  handleGenerate,
  handlePreview,
  handleChangeSpriteToSquare,
  handleChangeSpriteToRounded,
  updateCurrentSpriteOption,
  handleResizeWorkspace,
  handleComposeSprite,
  handleComposeText,
  isShowSaveTemplateDialog
} from './provider/workspace'

import { canvasBackgroundColorList, textColorList, textFontFamilyList } from './provider/dict'

onMounted(() => {
  setTimeout(() => {
    initWorkspace(route.query.id)
  }, 100)
})

const workspaceWrapStyle = computed(() => {
  let result = {}
  if (window.innerHeight - workspaceOption.value.height > 100) {
    result.alignItems = 'center'
  }
  if (window.innerWidth - workspaceOption.value.width > 400) {
    result.justifyContent = 'center'
  }
  return result
})
const workspaceStyle = computed(() => {
  return {
    width: workspaceOption.value.width + 'px',
    height: workspaceOption.value.height + 'px'
  }
})
</script>

<style lang="less" scoped>
.workspacePage {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
}
.section {
  padding: 10px 20px;
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-btn {
    display: block;
    margin: 0 10px 10px 0;
  }
}

.workspaceContainer {
  flex-grow: 1;
  height: var(--maxPageHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  position: relative;
  background: var(--color-dark);
  .workspaceWrap {
    position: relative;
    display: flex;
    overflow: auto;
    height: calc(var(--maxPageHeight) - 30px);
    width: calc(100vw - 350px);
    padding: 50px;
    #workspace {
      flex-shrink: 0;
      border: 1px solid var(--color-green);
      box-shadow: 0 0 6px var(--color-green);
    }
  }
  .statusBar {
    background: var(--color-red);
    border-top: 1px solid var(--color-red);
    border-bottom: 1px solid var(--color-red);
    box-shadow: 0 -1px 12px var(--color-red);
    position: fixed;
    left: 0;
    right: 350px;
    bottom: 0;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    color: white;
    .btn {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      &:hover {
        background: var(--color-dark);
      }
    }
  }
}

.controlPanel {
  border-left: 1px solid var(--color-blue);
  box-shadow: -1px 0 12px var(--color-blue);
  z-index: 2;
  width: 350px;
  background: var(--color-blue);
  flex-shrink: 0;
  height: var(--maxPageHeight);
  overflow-y: auto;
  .section {
    border-bottom: 1px solid var(--color-green);
    box-shadow: 3px 0 6px var(--color-green);
  }
  .sectionTitle {
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
    padding-left: 40px;
  }
}
</style>
