<template lang="pug">
a-modal(
  title="保存为模板" 
  ok-text="确认" 
  cancel-text="取消" 
  v-model:visible="isShowSaveTemplateDialog" 
  @ok="handleSubmitTemplate($route.query)"
)
  a-form
    a-form-item(label="名称")
      a-input(v-model:value="saveTemplateData.name")
    a-form-item(label="描述")
      a-input(v-model:value="saveTemplateData.desc")
    a-form-item(label="分类")
      a-select(v-model:value="saveTemplateData.category")
        a-select-option(v-for="i in postTypeList" :key="i.id" :value="i.id") {{i.name}}
    a-form-item(label="标签")
      a-tag(v-for="(i,n) in saveTemplateData.tagList" :key="n"
        color="orange" closable 
        @close="handleClose(i)") {{i}}
      a-input(v-if="saveTemplateData.inputVisible"
        ref="inputRef"
        type="text"
        size="small"
        :style="{ width: '78px' }"
        v-model:value="saveTemplateData.inputValue"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm")
      a-tag(v-else 
        @click="showInput"
        style="background: #fff; border-style: dashed")
        plus-outlined
        span 新标签

</template>

<script setup>
import { inject, reactive, ref, onMounted, nextTick } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import {
  isShowSaveTemplateDialog,
  handleSubmitTemplate,
  saveTemplateData
} from '../provider/workspace'
import { postTypeList } from '@/provider/dict'

const inputRef = ref()

const showInput = () => {
  saveTemplateData.value.inputVisible = true
  nextTick(() => {
    inputRef.value.focus()
  })
}

const handleInputConfirm = () => {
  const inputValue = saveTemplateData.value.inputValue
  let tags = saveTemplateData.value.tagList
  if (inputValue && tags.indexOf(inputValue) === -1) {
    tags = [...tags, inputValue]
  }
  saveTemplateData.value.tagList = tags
  saveTemplateData.value.inputVisible = false
  saveTemplateData.value.inputValue = ''
}

const handleClose = removedTag => {
  const tags = saveTemplateData.value.filter(tag => tag !== removedTag)
  saveTemplateData.value.tagList = tags
}
</script>

<style lang="less" scoped>
.imagePreview {
  max-width: 200px;
  max-height: 200px;
}
.textPreview {
  border: 1px dashed #ccc;
}
</style>
