import { ref, watch, readonly, nextTick } from 'vue'
import { app } from '@/main'
import { nanoid } from 'nanoid'
import { Scene, Sprite, Label, Group } from 'spritejs'
import { draggable } from 'next-draggable'

const fetch = window.$globalUtil.axios

import { pickImage } from '@/provider/file'

import * as QRCode from 'qrcode'

// 选中后的高亮边框颜色
const activeBorderColor = '#72f1ab'
const defaultFontFamily = '思源黑体'

// 工作区配置
export let workspaceOption = ref({
  width: 600,
  height: 960,
  isLockSizeRatio: true,
  background: 'grey',
  canvasBackground: '#FFFDCC'
})

// 当前选中的元素配置
export let spriteOption = ref({
  x: 0,
  y: 0,
  width: readonly(workspaceOption.value.width),
  height: readonly(workspaceOption.value.height),
  scale: 1,
  opacity: 1,
  zIndex: 1,
  borderRadius: 0,
  isLockSizeRatio: true,
  background: '',
  type: '',
  text: '',
  url: '',
  textStyle: {
    fontSize: 16,
    fillColor: 'black',
    textAlign: 'left',
    fontFamily: defaultFontFamily
  }
})

// 保存模板弹窗的表单数据
export let isShowSaveTemplateDialog = ref(false)
export let saveTemplateData = ref({
  name: '',
  desc: '',
  tagList: [],
  inputVisible: false,
  inputValue: ''
})

// 预览图片弹窗的数据
export let previewSrc = ref('')
export let isShowPreview = ref(false)

// 基础 canvas 容器
let scene = null
let backgroundLayer = null

// 元素列表
export let spriteList = ref([])
export let currentSpriteId = ref('')

// 操作历史记录
let history = ref([]) //{id,value,action=add/remove/edit}

export const initWorkspace = async id => {
  const container = document.querySelector('#workspace')
  if (id) {
    const sourceData = await getTemplateData(id)
    workspaceOption.value = sourceData.workspaceOption
    spriteList.value = sourceData.spriteList
    saveTemplateData.value.name = sourceData.name
    saveTemplateData.value.desc = sourceData.desc
    saveTemplateData.value.tagList = sourceData.tagList
  }
  if (scene) {
    scene.remove()
    scene.removeAllChildren()
  }
  nextTick(() => {
    scene = new Scene({
      container,
      width: workspaceOption.value.width,
      height: workspaceOption.value.height
    })
    handleReDrawWorkspace()
  })
}

export const handleClickSprite = spriteId => {
  currentSpriteId.value = spriteId
  refreshActiveSprite(spriteId)
}

export const handleAddImage = async () => {
  const id = 'sprite-' + nanoid(8)
  const zIndex = getNewIndex()
  const url = await pickImage({ type: 'resource' })
  const size = await getImageSize(url)
  let payload = {
    id,
    width: size[0],
    height: size[1],
    zIndex,
    url,
    x: 0,
    y: 0,
    borderRadius: 0,
    opacity: 1,
    scale: 1,
    type: 'image',
    show: true,
    className: `sprite sprite-image`
  }
  handleAddSprite(payload)
}

export const addComponent = async componentInfo => {
  const id = 'com-' + componentInfo.placeholderKey
  let payload = {
    id,
    placeholderKey: componentInfo.placeholderKey,
    x: 0,
    y: 0,
    zIndex: getNewIndex(),
    borderRadius: 0,
    type: componentInfo.type,
    opacity: 1,
    scale: 1,
    show: true,
    className: `sprite com-${componentInfo.type}`
  }
  if (componentInfo.type === 'image') {
    payload.url = componentInfo.defaultValue
    const size = await getImageSize(componentInfo.defaultValue)
    payload.width = size[0]
    payload.height = size[1]
    handleAddSprite(payload)
  }
  if (componentInfo.type === 'text') {
    payload.text = componentInfo.defaultValue
    handleAddLabel(payload)
  }
  if (componentInfo.type === 'textarea') {
    payload.text = componentInfo.defaultValue
    handleAddTextarea(payload)
  }
  if (componentInfo.type === 'qrCode') {
    payload.text = componentInfo.defaultValue
    handleAddQrcodeRaw(payload)
  }
}

export const handleAddQrcodeRaw = async options => {
  const qrCodeUrl = await getQrCodeUrl(options.text)
  const size = await getImageSize(qrCodeUrl)
  options.width = size[0]
  options.height = size[1]
  const layer = scene.layer()
  layer.attr({
    width: workspaceOption.value.width,
    height: workspaceOption.value.height
  })
  const sprite = draggable(new Sprite(qrCodeUrl))
  draggable(sprite, {
    dragRect: [
      10 - workspaceOption.value.width,
      10 - workspaceOption.value.height,
      workspaceOption.value.width - 10,
      workspaceOption.value.height - 10
    ]
  })
  sprite.attr({
    id: options.id,
    width: options.width,
    height: options.height,
    x: options.x,
    y: options.y,
    zIndex: options.zIndex,
    borderRadius: options.borderRadius,
    className: options.className,
    boxSizing: 'border-box'
  })

  sprite.addEventListener('click', e => {
    handleClickSprite(options.id)
  })
  sprite.addEventListener('dragend', e => {
    updateSpriteListItem(options.id)
  })
  currentSpriteId.value = options.id
  layer.append(sprite)
  const n = spriteList.value.findIndex(i => i.id === options.id)
  if (n >= 0) {
    spriteList.value.splice(n, 1, options)
  } else {
    spriteList.value.push(options)
  }
  console.log('>>>>>>> layer append, id: ', options.id)
}
export const handleAddTextarea = options => {
  console.log(options)
  options.width = workspaceOption.value.width
  options.height = 'auto'
  options.textStyle = {
    fontSize: 24,
    fillColor: '#c0392b',
    textAlign: 'center',
    strokeColor: 'white',
    strokeWidth: 0,
    fontWeight: 'normal',
    fontFamily: defaultFontFamily
  }
  const textLength = options.text.length
  const wordsOneLine = Math.floor(workspaceOption.value.width / options.textStyle.fontSize) - 1
  const lineNumber = Math.ceil(textLength / wordsOneLine)
  const layer = scene.layer()
  const group = new Group({
    id: options.id,
    className: 'sprite',
    size: [options.width, lineNumber * options.textStyle.fontSize + 4 * (lineNumber - 1)]
  })
  draggable(group, {
    dragRect: [
      10 - workspaceOption.value.width,
      10 - workspaceOption.value.height,
      workspaceOption.value.width - 10,
      workspaceOption.value.height - 10
    ]
  })
  group.addEventListener('click', e => {
    handleClickSprite(options.id)
  })
  group.addEventListener('dragend', e => {
    updateSpriteListItem(options.id)
  })
  for (let i = 0; i < lineNumber; i++) {
    const text = options.text.substr(i * wordsOneLine, wordsOneLine)
    const y = i * options.textStyle.fontSize * 1.2
    const childSprite = new Label(text)
    childSprite.attr({
      size: [],
      fontSize: options.textStyle.fontSize,
      fillColor: options.textStyle.fillColor,
      textAlign: options.textStyle.textAlign,
      fontWeight: options.textStyle.fontWeight,
      fontFamily: options.textStyle.fontFamily,
      id: `${options.id}-${i}`,
      x: options.x,
      y: y,
      zIndex: options.zIndex,
      className: options.className,
      boxSizing: 'border-box'
    })
    console.log('>>>>>>> options.className', options.className)
    if (options.textStyle.strokeWidth && options.textStyle.strokeColor) {
      childSprite.attr({
        strokeColor: options.textStyle.strokeColor,
        strokeWidth: options.textStyle.strokeWidth
      })
    }
    group.append(childSprite)
  }
  currentSpriteId.value = options.id
  layer.append(group)
  spriteList.value.push(options)
  console.log('>>>>>>> layer append, id: ', options.id)

  history.value.push({ id: options.id, options, action: 'add' })
}
export const handleAddLabel = options => {
  const layer = scene.layer()
  const label = new Label(options.text)
  draggable(label, {
    dragRect: [
      10 - workspaceOption.value.width,
      10 - workspaceOption.value.height,
      workspaceOption.value.width - 10,
      workspaceOption.value.height - 10
    ]
  })
  options.textStyle = {
    fontSize: 24,
    fillColor: '#c0392b',
    textAlign: 'left',
    strokeColor: 'white',
    strokeWidth: 0,
    fontWeight: 'normal',
    fontFamily: 'Microsoft YaHei'
  }
  label.attr({
    id: options.id,
    width: options.width,
    height: options.height,
    x: options.x,
    y: options.y,
    zIndex: options.zIndex,
    opacity: options.opacity,
    scale: options.scale,
    fontSize: options.textStyle.fontSize,
    fillColor: options.textStyle.fillColor,
    textAlign: options.textStyle.textAlign,
    fontFamily: options.textStyle.fontFamily,
    className: options.className
  })
  label.addEventListener('click', e => {
    handleClickSprite(options.id)
  })
  label.addEventListener('dragend', e => {
    updateSpriteListItem(options.id)
  })
  currentSpriteId.value = options.id
  layer.append(label)
  spriteList.value.push(options)
  console.log('>>>>>>> layer append, id: ', options.id)

  history.value.push({ id: options.id, options, action: 'add' })
}
export const handleAddSprite = options => {
  const layer = scene.layer()
  layer.attr({
    width: workspaceOption.value.width,
    height: workspaceOption.value.height
  })
  const sprite = draggable(new Sprite(options.url))
  draggable(sprite, {
    dragRect: [
      10 - workspaceOption.value.width,
      10 - workspaceOption.value.height,
      workspaceOption.value.width - 10,
      workspaceOption.value.height - 10
    ]
  })
  sprite.attr({
    id: options.id,
    width: options.width,
    height: options.height,
    x: options.x,
    y: options.y,
    zIndex: options.zIndex,
    borderRadius: options.borderRadius,
    className: options.className,
    boxSizing: 'border-box'
  })

  sprite.addEventListener('click', e => {
    handleClickSprite(options.id)
  })
  sprite.addEventListener('dragend', e => {
    updateSpriteListItem(options.id)
  })
  currentSpriteId.value = options.id
  layer.append(sprite)
  spriteList.value.push(options)
  console.log('>>>>>>> layer append, id: ', options.id)

  history.value.push({ id: options.id, options, action: 'add' })
}

export const handleDeleteSprite = spriteId => {
  const n = spriteList.value.findIndex(i => i.id === spriteId)
  spriteList.value.splice(n, 1)
  let currentSprite = scene.getElementById(spriteId)
  currentSprite.remove()
}

export const handleClearWorkspace = () => {
  spriteList.value = []
  scene.removeAllChildren()
  initWorkspace()
}

export const handleGenerate = () => {
  fetch
    .post('/boom-px-api-service/generate', {
      workspaceOption: workspaceOption.value,
      spriteList: spriteList.value
    })
    .then(url => {
      previewSrc.value = url
      isShowPreview.value = true
    })
}

export const handlePreview = () => {
  const canvas = scene.snapshot()
  previewSrc.value = canvas.toDataURL()
  isShowPreview.value = true
}

export const handleSubmitTemplate = query => {
  if (query.id) {
    fetch.put(`/boom-px-api-service/template/${query.id}`, {
      ...saveTemplateData.value,
      workspaceOption: workspaceOption.value,
      spriteList: spriteList.value
    })
  } else {
    fetch.post('/boom-px-api-service/template', {
      ...saveTemplateData.value,
      workspaceOption: workspaceOption.value,
      spriteList: spriteList.value
    })
  }
  isShowSaveTemplateDialog.value = false
}

export const afterSpriteScaleChange = scale => {
  let currentSpriteDom = scene.getElementById(currentSpriteId.value)
  if (spriteOption.value.type === 'image') {
    spriteOption.value.width = Math.floor(spriteOption.value.width * scale)
    spriteOption.value.height = Math.floor(spriteOption.value.height * scale)
    spriteOption.value.scale = 1
    currentSpriteDom.attr({
      width: spriteOption.value.width,
      height: spriteOption.value.height,
      scale: [1, 1]
    })
    let currentSprite = spriteList.value.find(i => i.id === currentSpriteId.value)
    currentSprite.width = readonly(spriteOption.value.width)
    currentSprite.height = readonly(spriteOption.value.height)
    currentSprite.scale = 1
  } else {
    let currentSprite = spriteList.value.find(i => i.id === currentSpriteId.value)
    currentSprite.scale = scale
    currentSpriteDom.attr({
      scale: [scale, scale]
    })
  }
}

export const handleReDrawWorkspace = () => {
  scene.removeAllChildren()
  backgroundLayer = scene.layer()
  backgroundLayer.canvas.style.backgroundColor = workspaceOption.value.canvasBackground
  for (let i = 0; i < spriteList.value.length; i++) {
    const spriteItem = spriteList.value[i]
    handleReDrawSprite(spriteItem.id)
  }
  scene.forceUpdate()
}

export const handleReDrawSprite = id => {
  const spriteItem = readonly(spriteList.value.find(i => i.id === id))
  const spriteItemDom = scene.getElementById(id)
  if (spriteItemDom) {
    spriteItemDom.remove()
  }
  if (spriteItem?.show) {
    const layer = scene.layer()
    if (spriteItem.type === 'image') {
      draggable(layer, {
        dragRect: [
          10 - workspaceOption.value.width,
          10 - workspaceOption.value.height,
          workspaceOption.value.width - 10,
          workspaceOption.value.height - 10
        ]
      })
      const sprite = draggable(new Sprite(spriteItem.url))
      sprite.addEventListener('click', e => {
        handleClickSprite(spriteItem.id)
      })
      sprite.addEventListener('dragend', e => {
        updateSpriteListItem(spriteItem.id)
      })
      sprite.attr({
        id: spriteItem.id,
        x: spriteItem.x,
        y: spriteItem.y,
        zIndex: spriteItem.zIndex,
        className: spriteItem.className,
        boxSizing: 'border-box',
        width: spriteItem.width,
        height: spriteItem.height,
        className: spriteItem.className,
        borderRadius: spriteItem.borderRadius,
        scale: spriteItem.scale,
        boxSizing: 'border-box'
      })
      layer.append(sprite)
    } else if (spriteItem.type === 'text') {
      const sprite = new Label(spriteItem.text)
      console.log('>>>>>>> spriteItem', spriteItem)
      sprite.attr({
        id: spriteItem.id,
        x: spriteItem.x,
        y: spriteItem.y,
        zIndex: spriteItem.zIndex,
        className: spriteItem.className,
        boxSizing: 'border-box',
        scale: spriteItem.scale,
        width: spriteItem.width,
        translate: spriteItem.translate,
        fontSize: spriteItem.textStyle.fontSize,
        fillColor: spriteItem.textStyle.fillColor,
        textAlign: spriteItem.textStyle.textAlign,
        fontWeight: spriteItem.textStyle.fontWeight,
        fontFamily: spriteItem.textStyle.fontFamily
      })

      draggable(sprite, {
        dragRect: [
          10 - workspaceOption.value.width,
          10 - workspaceOption.value.height,
          workspaceOption.value.width - 10,
          workspaceOption.value.height - 10
        ]
      })
      sprite.addEventListener('click', e => {
        handleClickSprite(spriteItem.id)
      })
      sprite.addEventListener('dragend', e => {
        updateSpriteListItem(spriteItem.id)
      })

      if (spriteItem.textStyle.strokeWidth && spriteItem.textStyle.strokeColor) {
        sprite.attr({
          strokeColor: spriteItem.textStyle.strokeColor,
          strokeWidth: spriteItem.textStyle.strokeWidth
        })
      }
      layer.append(sprite)
    } else if (spriteItem.type === 'textarea') {
      let groupHeight
      let groupWidth = spriteItem.width || workspaceOption.value.width
      let textLength = spriteItem.text.length
      const wordsOneLine = Math.floor(groupWidth / spriteItem.textStyle.fontSize)
      const lineNumber = Math.ceil(textLength / wordsOneLine)
      const fontSize = spriteItem.textStyle.fontSize
      const textArray = getTextAreaListByRow({
        groupWidth,
        fontSize,
        text: spriteItem.text
      })

      if (spriteItem.height && spriteItem.height !== 'auto') {
        groupHeight = spriteItem.height
      } else {
        groupHeight = 2 * spriteItem.textStyle.fontSize + 4
      }

      const group = new Group({
        id: spriteItem.id,
        className: 'sprite',
        x: spriteItem.x,
        y: spriteItem.y,
        scale: spriteItem.scale,
        size: [groupWidth, groupHeight],
        zIndex: spriteItem.zIndex,
        border: [1, activeBorderColor]
      })
      draggable(group, {
        dragRect: [
          10 - workspaceOption.value.width,
          10 - workspaceOption.value.height,
          workspaceOption.value.width - 10,
          workspaceOption.value.height - 10
        ]
      })
      group.addEventListener('click', e => {
        handleClickSprite(spriteItem.id)
      })
      group.addEventListener('dragend', e => {
        updateSpriteListItem(spriteItem.id)
      })
      let currentX = 0
      for (let i = 0; i < textArray.length; i++) {
        for (let j = 0; j < textArray[i].length; j++) {
          const { v, w } = textArray[i][j]
          const width = Math.ceil(fontSize * w)
          if (j === 0) {
            currentX = 0
          }
          const y = Math.round(i * fontSize * 1.2)
          const childSprite = new Label(v)
          childSprite.attr({
            size: [],
            width,
            fontSize,
            textAlign: 'center',
            fillColor: spriteItem.textStyle.fillColor,
            fontWeight: spriteItem.textStyle.fontWeight,
            fontFamily: spriteItem.textStyle.fontFamily,
            id: `${spriteItem.id}-${i}`,
            x: currentX,
            y: y,
            zIndex: spriteItem.zIndex,
            className: spriteItem.className,
            boxSizing: 'border-box'
          })
          if (spriteItem.textStyle.strokeWidth && spriteItem.textStyle.strokeColor) {
            childSprite.attr({
              strokeColor: spriteItem.textStyle.strokeColor,
              strokeWidth: spriteItem.textStyle.strokeWidth
            })
          }
          group.append(childSprite)
          currentX += width
        }
      }
      layer.append(group)
    } else if (spriteItem.type === 'qrCode') {
      handleAddQrcodeRaw(spriteItem)
    }
  }
  scene.forceUpdate()
}

export const isPlaceholderKeyExist = placeholderKey => {
  return !!spriteList.value.find(i => i.placeholderKey === placeholderKey)
}

export const updateCurrentSprite = () => {
  handleReDrawSprite(currentSpriteId.value)
}

export const handleChangeSpriteItemShow = id => {
  const currentSprite = spriteList.value.find(i => i.id === id)
  currentSprite.show = !currentSprite.show
  handleReDrawSprite(id)
}

export const handleChangeSpriteToSquare = () => {
  let currentSpriteItem = spriteList.value.find(i => i.id === currentSpriteId.value)
  currentSpriteItem.borderRadius = 0
  let currentSpriteDom = scene.getElementById(currentSpriteId.value)
  currentSpriteDom.attr({
    borderRadius: 0
  })
}

export const handleChangeSpriteToRounded = () => {
  let currentSpriteItem = spriteList.value.find(i => i.id === currentSpriteId.value)
  const borderRadius = Math.max(currentSpriteItem.width, currentSpriteItem.height) / 2
  spriteOption.value.borderRadius = borderRadius
  currentSpriteItem.borderRadius = borderRadius
  let currentSpriteDom = scene.getElementById(currentSpriteId.value)
  currentSpriteDom.attr({
    borderRadius
  })
}

export const updateCurrentSpriteOption = () => {
  const currentSpriteOption = readonly(spriteOption.value)
  let currentSpriteItem = spriteList.value.find(i => i.id === currentSpriteId.value)
  currentSpriteItem.x = currentSpriteOption.x
  currentSpriteItem.y = currentSpriteOption.y
  if (currentSpriteOption.width) {
    currentSpriteItem.width = currentSpriteOption.width
  }
  if (currentSpriteOption.height) {
    currentSpriteItem.height = currentSpriteOption.height
  }
  currentSpriteItem.scale = currentSpriteOption.scale
  currentSpriteItem.zIndex = currentSpriteOption.zIndex
  currentSpriteItem.borderRadius = currentSpriteOption.borderRadius
  currentSpriteItem.opacity = currentSpriteOption.opacity
  currentSpriteItem.textStyle = currentSpriteOption.textStyle
  currentSpriteItem.url = currentSpriteOption.url
  currentSpriteItem.text = currentSpriteOption.text
  currentSpriteItem.translate = currentSpriteOption.translate
  handleReDrawSprite(currentSpriteId.value)
}

export const handleComposeSprite = val => {
  if (val === 'left') {
    spriteOption.value.x = 0
  } else if (val === 'right') {
    const x = workspaceOption.value.width - spriteOption.value.width
    spriteOption.value.x = x
  } else if (val === 'center') {
    const x = workspaceOption.value.width - spriteOption.value.width
    spriteOption.value.x = x / 2
  }
  updateCurrentSpriteOption()
}

export const handleComposeText = val => {
  spriteOption.value.textStyle.textAlign = val
  updateCurrentSpriteOption()
}

const getTemplateData = id => {
  return fetch.get(`/boom-px-api-service/template/${id}`).then(res => {
    if (res?.spriteList?.length) {
      res.spriteList.map(i => {
        i.show = true
        return i
      })
    }
    return res
  })
}

function refreshActiveSprite() {
  const spriteDomList = scene.getElementsByClassName('sprite')
  spriteDomList.forEach(spriteItem => {
    if (spriteItem.id === currentSpriteId.value) {
      spriteItem.attr('border', [1, activeBorderColor])
      const spriteItemInList = spriteList.value.find(i => i.id === spriteItem.id)
      console.log(spriteItemInList)
      spriteOption.value.x = readonly(spriteItemInList.x)
      spriteOption.value.y = readonly(spriteItemInList.y)
      spriteOption.value.width = readonly(spriteItemInList.width)
      spriteOption.value.height = readonly(spriteItemInList.height)
      spriteOption.value.scale = readonly(spriteItemInList.scale)
      spriteOption.value.opacity = readonly(spriteItemInList.opacity)
      spriteOption.value.zIndex = readonly(spriteItemInList.zIndex)
      spriteOption.value.type = readonly(spriteItemInList.type)
      spriteOption.value.text = readonly(spriteItemInList.text)
      spriteOption.value.url = readonly(spriteItemInList.url)
      spriteOption.value.textStyle = readonly(spriteItemInList.textStyle)
    } else {
      spriteItem.attr('border', [0])
    }
  })
}

function updateSpriteListItem(spriteId) {
  const spriteItemDom = scene.getElementById(spriteId)
  const x = Math.floor(spriteItemDom.attributes.x)
  const y = Math.floor(spriteItemDom.attributes.y)
  spriteItemDom.attr({
    x,
    y
  })
  const n = spriteList.value.findIndex(i => i.id === spriteId)
  let currentSprite = spriteList.value[n]
  currentSprite.x = x
  currentSprite.y = y
}

export const handleResizeWorkspace = () => {
  if (
    workspaceOption.value.width !== scene.width ||
    workspaceOption.value.height !== scene.height
  ) {
    nextTick(() => {
      initWorkspace()
    })
  }
}

function handleResizeCurrentSprite() {
  const width = readonly(spriteOption.value.width)
  const height = readonly(spriteOption.value.height)
  let currentSpriteItem = spriteList.value.find(i => i.id === currentSpriteId.value)
  currentSpriteItem.width = width
  currentSpriteItem.height = height
  let currentSprite = scene.getElementById(currentSpriteId.value)
  currentSprite.attr({
    width,
    height
  })
}
function getImageSize(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      resolve([100, 100])
    }
    let image = new Image()
    image.src = url
    if (image.complete) {
      resolve(getSize())
    }
    image.onload = () => {
      resolve(getSize())
    }
    function getSize() {
      let width = image.width
      let height = image.height
      let ratio = 1
      if (width > workspaceOption.value.width) {
        ratio = workspaceOption.value.width / width
      } else if (height > workspaceOption.value.height) {
        ratio = workspaceOption.value.height / height
      }
      return [width * ratio, height * ratio]
    }
    image.onerror = err => {
      console.log('>>>>>>> err', err)
      resolve([100, 100])
    }
  })
}
const getNewIndex = () => {
  const spriteIndexList = spriteList.value.map(i => i.zIndex)
  let max = 1
  if (spriteIndexList.length) {
    max = Math.max(...spriteIndexList)
  }
  return max + 1
}

function getQrCodeUrl(text) {
  return new Promise((resolve, reject) => {
    if (!text) return ''
    QRCode.toDataURL(text, function(err, url) {
      resolve(url)
    })
  })
}

watch(
  () => workspaceOption.value.canvasBackground,
  val => {
    if (backgroundLayer) {
      backgroundLayer.canvas.style.backgroundColor = workspaceOption.value.canvasBackground
    }
  }
)
watch(
  () => spriteOption.value.scale,
  val => {
    let currentSprite = scene.getElementById(currentSpriteId.value)
    currentSprite.attr({
      scale: [val, val]
    })
  }
)

function getTextAreaListByRow({ groupWidth, fontSize, text }) {
  let res = []
  const textListWithWidth = text.split('').map(i => {
    let w = 1.05
    if (i.match(/[\x00-\xff]/g)) {
      w = 0.55
    }
    return {
      w,
      v: i
    }
  })
  const wordsOneLine = Math.floor(groupWidth / fontSize)
  for (let textItemWithWidth of textListWithWidth) {
    if (!res.length) {
      res.push([textItemWithWidth])
    } else {
      const lastRow = res[res.length - 1]
      const lastRowWidthTotal = lastRow.reduce((t, i) => (t = t + i.w), 0)
      if (lastRowWidthTotal + textItemWithWidth.w > wordsOneLine) {
        res.push([textItemWithWidth])
      } else {
        res[res.length - 1].push(textItemWithWidth)
      }
    }
  }
  if (res.length >= 2) {
    res = res.slice(0, 2)
    res[1] = res[1].slice(0, res[1].length - 2)
    res[1] = res[1].concat([
      { w: 0.5, v: '.' },
      { w: 0.5, v: '.' },
      { w: 0.5, v: '.' }
    ])
  }
  return res
}
