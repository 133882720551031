export const workspaceBackgroundColorList = [
  { label: '黑', value: 'black' },
  { label: '灰', value: 'grey' },
  { label: '白', value: 'white' },
  { label: '黄', value: '#FFFDCC' }
]
export const canvasBackgroundColorList = [
  { label: '黑', value: 'black' },
  { label: '灰', value: 'grey' },
  { label: '白', value: 'white' },
  { label: '黄', value: '#FFFDCC' },
  { label: '透明', value: 'transparent' }
]
export const textColorList = [
  { label: '黑', value: 'black' },
  { label: '灰', value: 'grey' },
  { label: '白', value: 'white' },
  { label: '红', value: '#c0392b' },
  { label: '橙', value: '#d35400' },
  { label: '黄', value: '#f39c12' },
  { label: '绿', value: '#27ae60' },
  { label: '青', value: '#16a085' },
  { label: '蓝', value: '#2980b9' },
  { label: '紫', value: '#8e44ad' }
]
export const textFontFamilyList = [
  { label: '思源黑体', value: '思源黑体' },
  { label: '胡晓波男神体', value: '胡晓波男神体' },
  { label: '胡晓波骚包体', value: '胡晓波骚包体' },
  { label: '胡晓波真帅体', value: '胡晓波真帅体' },
  { label: '优设标题黑', value: '优设标题黑' },
  { label: '方正仿宋简体', value: '方正仿宋简体' },
  { label: '雅黑', value: 'YaHei' },
  { label: '黑体', value: 'SimHei' },
  { label: '细黑', value: 'STXihei' },
  { label: '宋体', value: 'SimSun' },
  { label: '仿宋', value: 'FangSong' },
  { label: '新宋', value: 'NSimSun' },
  { label: '幼圆', value: 'YouYuan' },
  { label: '彩云', value: 'STCaiyun' },
  { label: '琥珀', value: 'STHupo' },
  { label: '楷体', value: 'KaiTi' },
  { label: '行楷', value: 'STXingkai' },
  { label: '隶书', value: 'LiSu' },
  { label: '舒体', value: 'FZShuTi' },
  { label: '姚体', value: 'FZYaoti' }
]
