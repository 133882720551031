<template lang="pug">
.spriteList
  .spriteItem(
    v-for="(i,n) in spriteList" :key="n"
    :class="{active:currentSpriteId===i.id}"
    @click="handleClickSprite(i.id)"
    draggable
    )
    .eye(@click="handleChangeSpriteItemShow(i.id)")
      EyeOutlined.active(v-if="i.show")
      EyeInvisibleOutlined.eye.inactive(v-else)
    .zIndex {{i.zIndex}}
    .imageSprite(v-if="i.type==='image'")
      img(:src="i.url")
    .textSprite(v-else-if="i.type==='text'")
      .text {{i.text}}
    .textSprite(v-else-if="i.type==='textarea'")
      .text 多行文本
    CloseOutlined.btn(@click="handleDeleteSprite(i.id)")
.actionBar
  a-button(type="danger" size="small" @click="handleAddImage") 添加图片
  a-button(type="danger" size="small" @click="isShowComponentPicker=true") 添加组件
</template>
<script setup>
import {
  spriteList,
  currentSpriteId,
  handleClickSprite,
  handleDeleteSprite,
  handleAddImage,
  handleChangeSpriteItemShow
} from '../provider/workspace'

import { CloseOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons-vue'
import { isShowComponentPicker } from '../provider/component'
</script>
<style lang="less" scoped>
.spriteList {
  .spriteItem {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid transparent;
    height: 50px;
    border-radius: 30px;
    .eye {
      width: 30px;
      text-align: center;
      .active {
        color: white;
      }
      .inactive {
        color: grey;
      }
    }
    .zIndex {
      width: 30px;
      pointer-events: none;
    }
    .imageSprite {
      flex-grow: 1;
      img {
        max-width: 40px;
        max-height: 40px;
      }
    }
    .textSprite {
      flex-grow: 1;
      overflow: hidden;
      pointer-events: none;
      user-select:none;
    }
    .btn {
      cursor: pointer;
      display: none;
      &:hover {
        color: var(--color-green);
      }
    }
    &.active {
      background-color: #ff2e97;
      color: white;
      .btn {
        display: block;
      }
    }
    &:hover {
      border-color: #ff2e97;
    }
  }
}
.actionBar {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
</style>
