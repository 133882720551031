import { ref, watch } from 'vue'
import { message } from 'ant-design-vue'
import { addComponent, isPlaceholderKeyExist } from './workspace'

export const buildInComponentList = [
  { type: 'text', id: 'userNickname', name: '用户昵称' },
  { type: 'text', id: 'storeName', name: '门店名称' },
  { type: 'text', id: 'merchantName', name: '商家名称' },
  { type: 'text', id: 'productName', name: '商品名称' },
  { type: 'textarea', id: 'productNameMultiLine', name: '商品名称（多行）' },
  { type: 'text', id: 'productSalePrice', name: '商品售价' },
  { type: 'text', id: 'productOriginPrice', name: '商品原价' },
  { type: 'text', id: 'productSaleTime', name: '商品售卖时间' },
  {
    type: 'image',
    id: 'userAvatar',
    name: '用户头像',
    value: 'http://file.cdn.tanchi.shop/local/px/resource/snap-5b914e4981fad29b127c2c0ea000f5d7.png'
  },
  {
    type: 'image',
    id: 'productImage',
    name: '商品图片',
    value: 'http://file.cdn.tanchi.shop/local/px/resource/snap-a39fca8258ee0e2a71ca06f881c144f2.png'
  },
  {
    type: 'image',
    id: 'qrCode',
    name: '二维码图片',
    value: 'http://file.cdn.tanchi.shop/dev/px/resource/snap-4aad261c63b284b7355c4b88825a6428.png'
  },
  {
    type: 'qrCode',
    id: 'qrCodeRaw',
    name: '二维码原文',
    value: 'https://www.boomsj.com'
  }
]

export const currentBuildInComponentId = ref('')

export const handleChangeBuildInComponent = val => {
  form.value.type = val.type
  form.value.placeholderKey = val.id
  form.value.defaultValue = val.value || val.name
  currentBuildInComponentId.value = val.id
}

export let isShowComponentPicker = ref(false)

export let form = ref({
  type: '',
  placeholderKey: '',
  defaultValue: ''
})

export const submitPickComponent = async () => {
  if (isPlaceholderKeyExist(form.value.placeholderKey)) {
    message.error('Key值已存在')
    return
  }
  await addComponent({ ...form.value })
  isShowComponentPicker.value = false
  form.value.type = ''
  form.value.placeholderKey = ''
  form.value.defaultValue = ''
  currentBuildInComponentId.value = ''
}
